export const listAmplifyGateways = /* GraphQL */ `
  query ListAmplifyGateways($filter: ModelAmplifyGatewayFilterInput, $limit: Int, $nextToken: String) {
    listAmplifyGateways(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        gateway_id
        gateway_name
        gateway_types
        archive
        gateway_currencies
        options
        access
        allowFetchProviderRawData
      }
      nextToken
    }
  }
`
export const listAmplifyProjects = /* GraphQL */ `
  query ListAmplifyProjects($filter: ModelAmplifyProjectFilterInput, $limit: Int, $nextToken: String) {
    listAmplifyProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        last_activity
        email
        groups
        type
        name
        callback_url
        is_callback_id
        merchant_name
        currencies
        private_access_config {
          default_name
          default_merchant_name
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const listBalanceOperationByType = /* GraphQL */ `
  query ListBalanceOperationByType(
    $idByType: String!
    $created_at: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAmplifyBalanceOperationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBalanceOperationByType(idByType: $idByType, created_at: $created_at, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        project_id
        type
        comment
        amount
        currency
        balance_before
        balance_after
        created_at
        idByType
        author
      }
      nextToken
    }
  }
`
export const listBalanceOperationByDate = /* GraphQL */ `
  query ListBalanceOperationByDate(
    $project_id: String!
    $created_at: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAmplifyBalanceOperationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBalanceOperationByDate(project_id: $project_id, created_at: $created_at, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        project_id
        type
        comment
        amount
        currency
        balance_before
        balance_after
        groups
        created_at
        idByType
        author
      }
      nextToken
    }
  }
`
