import { API } from 'aws-amplify'
import {
  listAmplifyPaymentsByDate,
  getPaymentByOrderId,
  getPaymentByPaymentId,
  getPaymentByProviderId,
  getPaymentByCard,
  getPaymentByProjectCurrency,
  loadTransactionDetails,
  getAmplifyPayment,
} from '../../graphql/queries'
import { createPaymentLink, createRefund, getTransactionsPrivateAccess, updatePayout } from '../../graphql/mutations'
import Logger from '../../tools/Logger'
import { isValidRange } from '../../tools/validators'

const getDefaultState = () => {
  return {
    payments: [],
    loading: false,
    nextNextToken: '',
    filter: {},
    selectedPayments: [],
  }
}

const state = getDefaultState()

const getters = {
  getPayments: (state) => state.payments,
  isLoading: (state) => state.loading,
  getNextNextToken: (state) => state.nextNextToken,
  getFilter: (state) => state.filter,
  getSelectedPayments: (state) => state.selectedPayments,
}

const actions = {
  async getPaymentsList({ commit }, { projectId, filter, nextToken, limit }) {
    commit('setLoading', true)
    try {
      const response = await API.graphql({
        query: listAmplifyPaymentsByDate,
        variables: {
          projectId,
          limit,
          ...filter,
          sortDirection: 'DESC',
          nextToken,
        },
      })
      Logger.log(response)
      commit('setPayments', response.data.listAmplifyPaymentsByDate.items)
      commit('setLoading', false)
      commit('setNextNextToken', response.data.listAmplifyPaymentsByDate.nextToken)
    } catch (err) {
      Logger.log(err)
      commit('setLoading', false)
      return {
        message: 'Something went wrong',
        success: false,
      }
    }
  },
  async getPaymentsByParameter({ commit, rootGetters }, { projectId, filter }) {
    // filterExpression - это условное обозначение филтра в данном случае, это не то же самое что filter поле
    const filterExpression = { eq: projectId }

    let query
    let responseQuery
    let response

    switch (filter.filterName) {
      case 'payment_id':
        query = getPaymentByPaymentId
        responseQuery = 'getPaymentByPaymentId'
        break
      case 'order_id':
        query = getPaymentByOrderId
        responseQuery = 'getPaymentByOrderId'
        break
      case 'provider_id':
        query = getPaymentByProviderId
        responseQuery = 'getPaymentByProviderId'
        break
      case 'card':
        query = getPaymentByCard
        responseQuery = 'getPaymentByCard'
        break
      default:
        Logger.log('wrong filter field')
        return {
          message: 'You can filter by transaction ID or order ID',
          success: false,
        }
    }
    try {
      const request = {
        [filter.filterName]: filter.filterValue.trim(),
        projectId: filterExpression,
      }
      const username = rootGetters['auth/isUser'].login
      // if global filter
      if (!projectId) {
        query = getTransactionsPrivateAccess // leave as is because it provides global search/ will refactor in future
        responseQuery = 'getTransactionsPrivateAccess'
        response = await API.graphql({
          query,
          variables: {
            input: {
              [filter.filterName]: filter.filterValue.trim(),
              filterById: true,
              username,
            },
          },
        })
        Logger.log(response)
        if (!response.data[`${responseQuery}`].success) {
          return {
            message: response.data[`${responseQuery}`].message,
            success: response.data[`${responseQuery}`].success,
          }
        }
        commit('setPayments', JSON.parse(response.data[`${responseQuery}`].items))
      } else if (projectId) {
        response = await API.graphql({
          query,
          variables: request,
        })
        Logger.log(response)
        commit('setPayments', response.data[`${responseQuery}`].items)
        commit('setNextNextToken', response.data[`${responseQuery}`].nextToken)
        commit('setFilter', { projectId: filterExpression })
      }
    } catch (err) {
      Logger.log(err)
      return {
        message: 'Something went wrong',
        success: false,
      }
    }
  },
  async getPaymentsByRange({ commit }, { projectId, filter, limit }) {
    const filterExpression = {
      between: [new Date(filter.startDate + 'Z').toISOString(), new Date(filter.endDate + 'Z').toISOString()],
    }
    Logger.log(filterExpression)
    try {
      const response = await API.graphql({
        query: listAmplifyPaymentsByDate,
        variables: {
          projectId,
          createdAt: filterExpression,
          sortDirection: 'DESC',
          limit,
        },
      })
      Logger.log(response)
      commit('setPayments', response.data.listAmplifyPaymentsByDate.items)
      commit('setNextNextToken', response.data.listAmplifyPaymentsByDate.nextToken)
      commit('setFilter', { createdAt: filterExpression })
    } catch (err) {
      Logger.log(err)
      return {
        message: 'Something went wrong',
        success: false,
      }
    }
  },
  async getPaymentsListByCurrency({ commit }, { projectCurrency, nextToken, limit }) {
    commit('setLoading', true)
    try {
      const response = await API.graphql({
        query: getPaymentByProjectCurrency,
        variables: {
          project_currency: projectCurrency,
          limit,
          sortDirection: 'DESC',
          nextToken,
        },
      })
      Logger.log(response)
      commit('setPayments', response.data.getPaymentByProjectCurrency.items)
      commit('setLoading', false)
      commit('setNextNextToken', response.data.getPaymentByProjectCurrency.nextToken)
    } catch (err) {
      Logger.log(err)
      commit('setLoading', false)
      return {
        message: 'Something went wrong',
        success: false,
      }
    }
  },
  //eslint-disable-next-line
  async getPaymentsForCSV({ commit }, { projectId, filter, includeFailed }) {
    let nextToken = null
    let response
    let paymentsArr = []
    if (!isValidRange(filter)) {
      return {
        message: 'Out of range, you can set up to 7 days',
        success: false,
      }
    }

    const filterExpression = {
      between: [filter.startDate.toString(), filter.endDate.toString()],
    }
    try {
      do {
        response = await API.graphql({
          query: listAmplifyPaymentsByDate,
          variables: {
            projectId,
            createdAt: filterExpression,
            sortDirection: 'DESC',
            limit: 1000,
            nextToken,
          },
        })
        Logger.log({ response })
        nextToken = response.data.listAmplifyPaymentsByDate.nextToken
        paymentsArr.push(...response.data.listAmplifyPaymentsByDate.items)
      } while (nextToken !== null)

      // filter payments
      if (!includeFailed) {
        paymentsArr = paymentsArr.filter((payment) => payment.transaction_status !== 'failed')
      }

      return paymentsArr.map((payment) => {
        let commission = null
        if (payment.net_amount) {
          commission = payment.fee || (+payment.amount - +payment.net_amount).toFixed(2)
        }

        return [
          payment.payment_id,
          payment.transaction_type,
          payment.transaction_status,
          payment.payment_system,
          payment.currency,
          payment.amount,
          commission,
          payment.order_id,
          payment.card,
          payment.createdAt,
          payment.updatedAt,
        ]
      })
    } catch (err) {
      Logger.log(err)
      return {
        message: 'Something went wrong',
        success: false,
      }
    }
  },
  // eslint-disable-next-line
  async updatePaymentStatus({ commit }, payments) {
    if (payments.length === 0) {
      return {
        message: 'You should choose at least one payment',
        success: false,
      }
    }
    try {
      Logger.log(payments)
      for await (const payment of payments) {
        if (!/internal/.test(payment.payment_id)) {
          const data = {
            payment_id: payment.payment_id,
            project: payment.projectId,
          }

          const response = await API.graphql({
            query: updatePayout,
            variables: { input: data },
          })
          Logger.log(response)
          if (response && response.data.updatePayout.success === false) {
            return response.data.updatePayout
          }
          Logger.log('res', response.data.updatePayout)
          commit('updatePayments', JSON.parse(response.data.updatePayout.result))
        }
      }

      return {
        message: 'Payment has been updated',
        success: true,
      }
    } catch (err) {
      Logger.log(err)
      return {
        message: 'Something went wrong',
        success: false,
      }
    }
  },
  async bulkUpdatePaymentStatus({ commit }, { ids, project }) {
    commit('setLoading', true)
    try {
      const transactions = []
      for (const id of ids) {
        if (!/internal/.test(id)) {
          const data = {
            payment_id: id,
            project: project,
          }

          const response = await API.graphql({
            query: updatePayout,
            variables: { input: data },
          })
          Logger.log(response)
          if (response && response.data.updatePayout.success === false) {
            // TODO: сохранять количество ошибок и потом отобразить
          } else {
            transactions.push(JSON.parse(response.data.updatePayout.result))
          }
        }
      }
      commit('setPayments', transactions)
      commit('setLoading', false)
    } catch (err) {
      Logger.log(err)
      commit('setLoading', false)
      return {
        message: 'Something went wrong',
        success: false,
      }
    }
  },
  clearFilter({ commit }) {
    commit('clearFilter', {})
  },
  clearSelectedPayments({ commit }) {
    commit('clearSelectedPayments', [])
  },
  addToSelectedPayments({ commit }, payment) {
    commit('addToSelectedPayments', payment)
  },
  clearAllPayments({ commit }) {
    commit('clearAllPayments', [])
  },
  // eslint-disable-next-line
  async refundTransaction({ commit }, payments) {
    if (payments.length > 1) {
      return {
        message: 'You can choose only one transaction at a time to create a refund',
        success: false,
      }
    }
    /*
     * TODO: make a call to db to fetch secret_key. which table to use?
     * */
    const data = {
      payment_id: payments[0].payment_id,
      project: payments[0].projectId,
    }
    Logger.log(data)
    try {
      const response = await API.graphql({
        query: createRefund,
        variables: { input: data },
      })
      Logger.log(response)
      return response.data.createRefund
    } catch (err) {
      Logger.log(err)
      return {
        message: 'Something went wrong',
        success: false,
      }
    }
  },
  async createPaymentLink({ commit }, obj) {
    commit('setLoading', true)
    try {
      const copyObj = {}
      for (const [key, value] of Object.entries(obj)) {
        if (value) copyObj[key] = value
      }
      const res = await API.graphql({
        query: createPaymentLink,
        variables: {
          input: copyObj,
        },
      })
      commit('setLoading', false)
      return res.data.createPaymentLink
    } catch (err) {
      Logger.log(err)
      commit('setLoading', false)
      return {
        message: 'Failed to create a payment link',
        success: false,
      }
    }
  },
  async getPaymentDetails({ dispatch }, { transactionId, projectId }) {
    try {
      const transactionDetails = await API.graphql({
        query: loadTransactionDetails,
        variables: {
          input: {
            transactionId,
            projectId,
          },
        },
      })
      if (!transactionDetails.data.loadTransactionDetails.success) {
        dispatch('alert/setAlertData', { success: transactionDetails.data.success, message: transactionDetails.data.loadTransactionDetails.message }, { root: true })
        return
      }
      return JSON.parse(transactionDetails.data.loadTransactionDetails.result)
    } catch (err) {
      Logger.log(err)
      dispatch('alert/setAlertData', { success: false, message: 'Failed to fetch transaction info' }, { root: true })
    }
  },
  async getPaymentById({ commit }, transactionId) {
    try {
      const res = await API.graphql({
        query: getAmplifyPayment,
        variables: { id: transactionId },
      })
      const payment = res?.data?.getAmplifyPayment
      if (payment) {
        return {
          success: true,
          data: payment,
        }
      } else {
        Logger.log('Payment data not found for transaction ID:', transactionId)
        return {
          message: 'Payment data not found.',
          success: false,
        }
      }
    } catch (err) {
      Logger.log(err)
      commit('setLoading', false)
      return {
        message: 'Error fetching payment details.',
        success: false,
      }
    }
  },
}

const mutations = {
  setPayments: (state, payments) => (state.payments = payments),
  setLoading: (state, loading) => (state.loading = loading),
  setNextNextToken: (state, token) => (state.nextNextToken = token),
  setFilter: (state, filter) => (state.filter = filter),
  clearFilter: (state, filter) => (state.filter = filter),
  addToSelectedPayments: (state, { checked, transaction }) => {
    if (state.selectedPayments.length > 0) {
      state.selectedPayments = state.selectedPayments.filter((p) => p.payment_id !== transaction.payment_id)
      if (checked) state.selectedPayments = [...state.selectedPayments, transaction]
    } else {
      state.selectedPayments = [...state.selectedPayments, transaction]
    }
  },
  clearSelectedPayments: (state, payload) => (state.selectedPayments = payload),
  clearAllPayments: (state, payload) => (state.payments = payload),
  updatePayments: (state, payload) => {
    state.payments = state.payments.map((payment) => {
      if (payment.payment_id === payload.payment_id) {
        return payload
      }
      return payment
    })
  },
  clearState: (state) => {
    Object.assign(state, getDefaultState())
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
