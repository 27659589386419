/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getBalance = /* GraphQL */ `
  query GetBalance($input: GetBalanceInput!) {
    getBalance(input: $input) {
      success
      balances {
        currency
        total_balance
        hold_amount
        available_balance
        inflight_balance
        commission_balance
        poolConfig
        __typename
      }
      usdtBalance
      message
      balanceByDate {
        balance
        currency
        timestamp
        __typename
      }
      __typename
    }
  }
`;
export const loadUsers = /* GraphQL */ `
  query LoadUsers {
    loadUsers {
      message
      success
      result
      __typename
    }
  }
`;
export const loadProjectSettings = /* GraphQL */ `
  query LoadProjectSettings($input: LoadProjectSettingsInput!) {
    loadProjectSettings(input: $input) {
      success
      message
      payload
      __typename
    }
  }
`;
export const loadTransactionDetails = /* GraphQL */ `
  query LoadTransactionDetails($input: LoadTransactionDetailsInput!) {
    loadTransactionDetails(input: $input) {
      success
      message
      result
      __typename
    }
  }
`;
export const getAmplifyExportReportHelper = /* GraphQL */ `
  query GetAmplifyExportReportHelper($id: ID!) {
    getAmplifyExportReportHelper(id: $id) {
      id
      result
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAmplifyExportReportHelpers = /* GraphQL */ `
  query ListAmplifyExportReportHelpers(
    $filter: ModelAmplifyExportReportHelperFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAmplifyExportReportHelpers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        result
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAmplifyBalanceOperation = /* GraphQL */ `
  query GetAmplifyBalanceOperation($id: ID!) {
    getAmplifyBalanceOperation(id: $id) {
      id
      project_id
      type
      comment
      amount
      currency
      balance_before
      balance_after
      groups
      created_at
      idByType
      author
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAmplifyBalanceOperations = /* GraphQL */ `
  query ListAmplifyBalanceOperations(
    $filter: ModelAmplifyBalanceOperationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAmplifyBalanceOperations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        project_id
        type
        comment
        amount
        currency
        balance_before
        balance_after
        groups
        created_at
        idByType
        author
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAmplifyGateway = /* GraphQL */ `
  query GetAmplifyGateway($id: ID!) {
    getAmplifyGateway(id: $id) {
      id
      gateway_id
      gateway_name
      gateway_currencies
      gateway_types
      archive
      options
      access
      groups
      allowFetchProviderRawData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAmplifyGateways = /* GraphQL */ `
  query ListAmplifyGateways(
    $filter: ModelAmplifyGatewayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAmplifyGateways(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        gateway_id
        gateway_name
        gateway_currencies
        gateway_types
        archive
        options
        access
        groups
        allowFetchProviderRawData
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAmplifyProject = /* GraphQL */ `
  query GetAmplifyProject($id: ID!) {
    getAmplifyProject(id: $id) {
      id
      active
      last_activity
      email
      groups
      type
      name
      callback_url
      is_callback_id
      payment_gateway
      payment_gateways
      merchant_name
      provider_name
      option_id
      currencies
      private_access_config {
        default_name
        default_merchant_name
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAmplifyProjects = /* GraphQL */ `
  query ListAmplifyProjects(
    $filter: ModelAmplifyProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAmplifyProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        active
        last_activity
        email
        groups
        type
        name
        callback_url
        is_callback_id
        payment_gateway
        payment_gateways
        merchant_name
        provider_name
        option_id
        currencies
        private_access_config {
          default_name
          default_merchant_name
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAmplifyPayment = /* GraphQL */ `
  query GetAmplifyPayment($id: ID!) {
    getAmplifyPayment(id: $id) {
      id
      payment_id
      order_id
      transaction_type
      payment_system
      projectId
      raw_error_message
      provider_id
      amount
      net_amount
      fee
      error_code
      error_description
      card
      currency
      transaction_status
      groups
      createdAt
      updatedAt
      project_currency
      raw_request {
        description
        failure_url
        ip
        project
        result_url
        signature
        success_url
        tokenData {
          country
          expiration_month
          expiration_year
          issuer
          number
          type
          scheme
          __typename
        }
        user_contact_email
        user_name
        user_phone
        user_address
        user_city
        user_state
        user_country
        user_postal_code
        __typename
      }
      __typename
    }
  }
`;
export const listAmplifyPayments = /* GraphQL */ `
  query ListAmplifyPayments(
    $filter: ModelAmplifyPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAmplifyPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        payment_id
        order_id
        transaction_type
        payment_system
        projectId
        raw_error_message
        provider_id
        amount
        net_amount
        fee
        error_code
        error_description
        card
        currency
        transaction_status
        groups
        createdAt
        updatedAt
        project_currency
        raw_request {
          description
          failure_url
          ip
          project
          result_url
          signature
          success_url
          tokenData {
            country
            expiration_month
            expiration_year
            issuer
            number
            type
            scheme
            __typename
          }
          user_contact_email
          user_name
          user_phone
          user_address
          user_city
          user_state
          user_country
          user_postal_code
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAmplifyPayout = /* GraphQL */ `
  query GetAmplifyPayout($id: ID!) {
    getAmplifyPayout(id: $id) {
      id
      payout_id
      order_id
      transaction_type
      projectId
      provider_id
      raw_error_message
      amount
      full_amount
      fee
      amount_gate
      groups
      card
      qiwi
      createdAt
      updatedAt
      currency
      payout_status
      user_name
      user_contact_email
      user_phone
      error_code
      error_message
      __typename
    }
  }
`;
export const listAmplifyPayouts = /* GraphQL */ `
  query ListAmplifyPayouts(
    $filter: ModelAmplifyPayoutFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAmplifyPayouts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        payout_id
        order_id
        transaction_type
        projectId
        provider_id
        raw_error_message
        amount
        full_amount
        fee
        amount_gate
        groups
        card
        qiwi
        createdAt
        updatedAt
        currency
        payout_status
        user_name
        user_contact_email
        user_phone
        error_code
        error_message
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAmplifyRefund = /* GraphQL */ `
  query GetAmplifyRefund($id: ID!) {
    getAmplifyRefund(id: $id) {
      id
      payment_id
      refund_id
      projectId
      amount
      transaction_status
      groups
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAmplifyRefunds = /* GraphQL */ `
  query ListAmplifyRefunds(
    $filter: ModelAmplifyRefundFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAmplifyRefunds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        payment_id
        refund_id
        projectId
        amount
        transaction_status
        groups
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listBalanceOperationByDate = /* GraphQL */ `
  query ListBalanceOperationByDate(
    $project_id: String!
    $created_at: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAmplifyBalanceOperationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBalanceOperationByDate(
      project_id: $project_id
      created_at: $created_at
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        project_id
        type
        comment
        amount
        currency
        balance_before
        balance_after
        groups
        created_at
        idByType
        author
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listBalanceOperationByType = /* GraphQL */ `
  query ListBalanceOperationByType(
    $idByType: String!
    $created_at: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAmplifyBalanceOperationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBalanceOperationByType(
      idByType: $idByType
      created_at: $created_at
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        project_id
        type
        comment
        amount
        currency
        balance_before
        balance_after
        groups
        created_at
        idByType
        author
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPaymentByPaymentId = /* GraphQL */ `
  query GetPaymentByPaymentId(
    $payment_id: String!
    $projectId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAmplifyPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPaymentByPaymentId(
      payment_id: $payment_id
      projectId: $projectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        payment_id
        order_id
        transaction_type
        payment_system
        projectId
        raw_error_message
        provider_id
        amount
        net_amount
        fee
        error_code
        error_description
        card
        currency
        transaction_status
        groups
        createdAt
        updatedAt
        project_currency
        raw_request {
          description
          failure_url
          ip
          project
          result_url
          signature
          success_url
          tokenData {
            country
            expiration_month
            expiration_year
            issuer
            number
            type
            scheme
            __typename
          }
          user_contact_email
          user_name
          user_phone
          user_address
          user_city
          user_state
          user_country
          user_postal_code
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPaymentByOrderId = /* GraphQL */ `
  query GetPaymentByOrderId(
    $order_id: String!
    $projectId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAmplifyPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPaymentByOrderId(
      order_id: $order_id
      projectId: $projectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        payment_id
        order_id
        transaction_type
        payment_system
        projectId
        raw_error_message
        provider_id
        amount
        net_amount
        fee
        error_code
        error_description
        card
        currency
        transaction_status
        groups
        createdAt
        updatedAt
        project_currency
        raw_request {
          description
          failure_url
          ip
          project
          result_url
          signature
          success_url
          tokenData {
            country
            expiration_month
            expiration_year
            issuer
            number
            type
            scheme
            __typename
          }
          user_contact_email
          user_name
          user_phone
          user_address
          user_city
          user_state
          user_country
          user_postal_code
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listAmplifyPaymentsByDate = /* GraphQL */ `
  query ListAmplifyPaymentsByDate(
    $projectId: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAmplifyPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAmplifyPaymentsByDate(
      projectId: $projectId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        payment_id
        order_id
        transaction_type
        payment_system
        projectId
        raw_error_message
        provider_id
        amount
        net_amount
        fee
        error_code
        error_description
        card
        currency
        transaction_status
        groups
        createdAt
        updatedAt
        project_currency
        raw_request {
          description
          failure_url
          ip
          project
          result_url
          signature
          success_url
          tokenData {
            country
            expiration_month
            expiration_year
            issuer
            number
            type
            scheme
            __typename
          }
          user_contact_email
          user_name
          user_phone
          user_address
          user_city
          user_state
          user_country
          user_postal_code
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listAmplifyPaymentsByUpdatedAt = /* GraphQL */ `
  query ListAmplifyPaymentsByUpdatedAt(
    $projectId: String!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAmplifyPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAmplifyPaymentsByUpdatedAt(
      projectId: $projectId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        payment_id
        order_id
        transaction_type
        payment_system
        projectId
        raw_error_message
        provider_id
        amount
        net_amount
        fee
        error_code
        error_description
        card
        currency
        transaction_status
        groups
        createdAt
        updatedAt
        project_currency
        raw_request {
          description
          failure_url
          ip
          project
          result_url
          signature
          success_url
          tokenData {
            country
            expiration_month
            expiration_year
            issuer
            number
            type
            scheme
            __typename
          }
          user_contact_email
          user_name
          user_phone
          user_address
          user_city
          user_state
          user_country
          user_postal_code
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPaymentByProviderId = /* GraphQL */ `
  query GetPaymentByProviderId(
    $provider_id: String!
    $projectId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAmplifyPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPaymentByProviderId(
      provider_id: $provider_id
      projectId: $projectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        payment_id
        order_id
        transaction_type
        payment_system
        projectId
        raw_error_message
        provider_id
        amount
        net_amount
        fee
        error_code
        error_description
        card
        currency
        transaction_status
        groups
        createdAt
        updatedAt
        project_currency
        raw_request {
          description
          failure_url
          ip
          project
          result_url
          signature
          success_url
          tokenData {
            country
            expiration_month
            expiration_year
            issuer
            number
            type
            scheme
            __typename
          }
          user_contact_email
          user_name
          user_phone
          user_address
          user_city
          user_state
          user_country
          user_postal_code
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPaymentByCard = /* GraphQL */ `
  query GetPaymentByCard(
    $card: String!
    $projectId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAmplifyPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPaymentByCard(
      card: $card
      projectId: $projectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        payment_id
        order_id
        transaction_type
        payment_system
        projectId
        raw_error_message
        provider_id
        amount
        net_amount
        fee
        error_code
        error_description
        card
        currency
        transaction_status
        groups
        createdAt
        updatedAt
        project_currency
        raw_request {
          description
          failure_url
          ip
          project
          result_url
          signature
          success_url
          tokenData {
            country
            expiration_month
            expiration_year
            issuer
            number
            type
            scheme
            __typename
          }
          user_contact_email
          user_name
          user_phone
          user_address
          user_city
          user_state
          user_country
          user_postal_code
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPaymentByProjectCurrency = /* GraphQL */ `
  query GetPaymentByProjectCurrency(
    $project_currency: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAmplifyPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPaymentByProjectCurrency(
      project_currency: $project_currency
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        payment_id
        order_id
        transaction_type
        payment_system
        projectId
        raw_error_message
        provider_id
        amount
        net_amount
        fee
        error_code
        error_description
        card
        currency
        transaction_status
        groups
        createdAt
        updatedAt
        project_currency
        raw_request {
          description
          failure_url
          ip
          project
          result_url
          signature
          success_url
          tokenData {
            country
            expiration_month
            expiration_year
            issuer
            number
            type
            scheme
            __typename
          }
          user_contact_email
          user_name
          user_phone
          user_address
          user_city
          user_state
          user_country
          user_postal_code
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPayoutsByPayoutId = /* GraphQL */ `
  query GetPayoutsByPayoutId(
    $payout_id: String!
    $projectId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAmplifyPayoutFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPayoutsByPayoutId(
      payout_id: $payout_id
      projectId: $projectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        payout_id
        order_id
        transaction_type
        projectId
        provider_id
        raw_error_message
        amount
        full_amount
        fee
        amount_gate
        groups
        card
        qiwi
        createdAt
        updatedAt
        currency
        payout_status
        user_name
        user_contact_email
        user_phone
        error_code
        error_message
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPayoutsByOrderId = /* GraphQL */ `
  query GetPayoutsByOrderId(
    $order_id: String!
    $projectId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAmplifyPayoutFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPayoutsByOrderId(
      order_id: $order_id
      projectId: $projectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        payout_id
        order_id
        transaction_type
        projectId
        provider_id
        raw_error_message
        amount
        full_amount
        fee
        amount_gate
        groups
        card
        qiwi
        createdAt
        updatedAt
        currency
        payout_status
        user_name
        user_contact_email
        user_phone
        error_code
        error_message
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listPayoutsByDate = /* GraphQL */ `
  query ListPayoutsByDate(
    $projectId: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAmplifyPayoutFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayoutsByDate(
      projectId: $projectId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        payout_id
        order_id
        transaction_type
        projectId
        provider_id
        raw_error_message
        amount
        full_amount
        fee
        amount_gate
        groups
        card
        qiwi
        createdAt
        updatedAt
        currency
        payout_status
        user_name
        user_contact_email
        user_phone
        error_code
        error_message
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listPayoutsByUpdatedAt = /* GraphQL */ `
  query ListPayoutsByUpdatedAt(
    $projectId: String!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAmplifyPayoutFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayoutsByUpdatedAt(
      projectId: $projectId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        payout_id
        order_id
        transaction_type
        projectId
        provider_id
        raw_error_message
        amount
        full_amount
        fee
        amount_gate
        groups
        card
        qiwi
        createdAt
        updatedAt
        currency
        payout_status
        user_name
        user_contact_email
        user_phone
        error_code
        error_message
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPayoutByProviderId = /* GraphQL */ `
  query GetPayoutByProviderId(
    $provider_id: String!
    $projectId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAmplifyPayoutFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPayoutByProviderId(
      provider_id: $provider_id
      projectId: $projectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        payout_id
        order_id
        transaction_type
        projectId
        provider_id
        raw_error_message
        amount
        full_amount
        fee
        amount_gate
        groups
        card
        qiwi
        createdAt
        updatedAt
        currency
        payout_status
        user_name
        user_contact_email
        user_phone
        error_code
        error_message
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPayoutByCard = /* GraphQL */ `
  query GetPayoutByCard(
    $card: String!
    $projectId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAmplifyPayoutFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPayoutByCard(
      card: $card
      projectId: $projectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        payout_id
        order_id
        transaction_type
        projectId
        provider_id
        raw_error_message
        amount
        full_amount
        fee
        amount_gate
        groups
        card
        qiwi
        createdAt
        updatedAt
        currency
        payout_status
        user_name
        user_contact_email
        user_phone
        error_code
        error_message
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listPayoutsByPayoutStatus = /* GraphQL */ `
  query ListPayoutsByPayoutStatus(
    $payout_status: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAmplifyPayoutFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayoutsByPayoutStatus(
      payout_status: $payout_status
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        payout_id
        order_id
        transaction_type
        projectId
        provider_id
        raw_error_message
        amount
        full_amount
        fee
        amount_gate
        groups
        card
        qiwi
        createdAt
        updatedAt
        currency
        payout_status
        user_name
        user_contact_email
        user_phone
        error_code
        error_message
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRefundByRefundId = /* GraphQL */ `
  query GetRefundByRefundId(
    $refund_id: String!
    $projectId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAmplifyRefundFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRefundByRefundId(
      refund_id: $refund_id
      projectId: $projectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        payment_id
        refund_id
        projectId
        amount
        transaction_status
        groups
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listRefundByDate = /* GraphQL */ `
  query ListRefundByDate(
    $projectId: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAmplifyRefundFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRefundByDate(
      projectId: $projectId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        payment_id
        refund_id
        projectId
        amount
        transaction_status
        groups
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
