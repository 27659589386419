import { API } from 'aws-amplify'
import { listAmplifyProjects as customListAmplifyProjects } from '../../graphql/custom-queries'
import { listAmplifyProjects } from '../../graphql/queries'
import Logger from '@/tools/Logger'
import { activateProject, createProject, getTransactionsPrivateAccess } from '@/graphql/mutations'

const getDefaultState = () => {
  return {
    projects: [],
    currentProject: '',
    isLoading: true,
    lastTransactions: [],
    lastLoading: true,
    switchLoading: false,
    filters: {
      merchant: '',
      name: '',
      provider: '',
      currency: '',
      type: 'All',
    },
  }
}

const state = getDefaultState()

const getters = {
  getProjects: (state) => {
    return state.projects.slice().sort((a, b) => {
      return (a.name || '').localeCompare(b.name || '')
    })
  },
  getFilteredProjects: (state) => {
    const { merchant, name, provider, currency, type } = state.filters

    const filteredProjects = state.projects.filter((project) => {
      const matchesMerchant = merchant ? project.merchant_name?.toLowerCase() === merchant.toLowerCase() : true
      const matchesName = name ? project.name?.toLowerCase() === name.toLowerCase() : true
      const matchesProvider = provider ? project.provider_name?.toLowerCase() === provider.toLowerCase() : true
      const matchesType = type !== 'All' ? project.type === type : true
      const matchesCurrency = currency ? project.currencies?.some((cur) => cur.toLowerCase() === currency.toLowerCase()) : true
      return matchesMerchant && matchesName && matchesProvider && matchesType && matchesCurrency
    })
    return filteredProjects
  },
  isLoading: (state) => state.isLoading,
  isSwitchLoading: (state) => state.switchLoading,
  getCurrentProject: (state) => state.currentProject,
  getLastTransaction: (state) => state.lastTransactions,
  isLastLoading: (state) => state.lastLoading,
  getFilters: (state) => state.filters,
}

const actions = {
  async listAmplifyProjects({ commit, rootState }) {
    commit('loading', true)
    let nextToken = null
    let projects = []
    do {
      const response = await API.graphql({
        query:
          rootState.auth.user && (rootState.auth.user.groups.includes('ap:raw_data_access') || rootState.auth.user.groups.includes('ap:full_access')) ? listAmplifyProjects : customListAmplifyProjects,
        variables: {
          nextToken,
        },
      })
      Logger.log({ response })
      nextToken = response.data.listAmplifyProjects.nextToken
      projects.push(...response.data.listAmplifyProjects.items)
    } while (nextToken !== null)

    commit('setProjects', projects)
    commit('loading', false)
  },
  async fetchLastTransactions({ commit, dispatch }) {
    commit('lastLoading', true)
    if (state.projects.length === 0) {
      commit('lastLoading', false)
      return
    }
    const response = await API.graphql({
      query: getTransactionsPrivateAccess,
      variables: {
        input: {
          projects: state.projects.map((p) => p.id),
        },
      },
    })
    if (!response.data.getTransactionsPrivateAccess.success) {
      dispatch('alert/setAlertData', response.data.getTransactionsPrivateAccess, { root: true })
      return
    }
    commit('setLastTransactions', JSON.parse(response.data.getTransactionsPrivateAccess.result))
    commit('lastLoading', false)
  },
  setProject({ commit }, payload) {
    commit('setProject', payload)
  },
  async createProject({ commit }, payload) {
    commit('loading', true)
    // validation
    if (state.projects.map((pr) => pr.name).includes(payload.projectName)) {
      commit('loading', false)
      return {
        success: false,
        message: 'Project name already exists',
      }
    }
    if (payload.merchantName.includes(' ')) {
      commit('loading', false)
      return {
        success: false,
        message: "Merchant name can't have spaces",
      }
    }
    try {
      const response = await API.graphql({
        query: createProject,
        variables: {
          input: {
            environment: payload.env,
            projectName: payload.projectName.trim(),
            merchantName: payload.merchantName.trim().toLowerCase(),
            gatewayName: payload.gatewayName.trim(),
            gatewayId: payload.gatewayId,
            gatewayType: payload.gatewayType,
            gatewayOption: payload.gatewayOption,
            gatewayCurrency: payload.gatewayCurrency,
            projectType: payload.projectType,
          },
        },
      })
      Logger.log(response)
      commit('loading', false)
      return response.data.createProject
    } catch (err) {
      Logger.log(err)
      commit('loading', false)
      return {
        success: false,
        message: 'Contact technical support, please',
      }
    }
  },
  // eslint-disable-next-line
  async changeActiveProjectState({ commit, dispatch }, { project, active }) {
    try {
      commit('switchLoading', true)
      const res = await API.graphql({
        query: activateProject,
        variables: {
          input: {
            project,
            active,
          },
        },
      })
      Logger.log(res.data.activateProject)
      dispatch('alert/setAlertData', res.data.activateProject, { root: true })
      commit('activeProjectState', { active, project })
      commit('switchLoading', false)
      return res.data.activateProject
    } catch (err) {
      Logger.log(err)
      dispatch('alert/setAlertData', { success: false, message: 'Contact technical support, please' }, { root: true })
      commit('switchLoading', false)
      return { success: false }
    }
  },
}

const mutations = {
  setProjects: (state, projects) => (state.projects = projects),
  setProject: (state, payload) => (state.currentProject = payload),
  loading: (state, payload) => (state.isLoading = payload),
  switchLoading: (state, payload) => (state.switchLoading = payload),
  setLastTransactions: (state, payload) => (state.lastTransactions = payload),
  lastLoading: (state, payload) => (state.lastLoading = payload),
  clearState: (state) => {
    Object.assign(state, getDefaultState())
  },
  activeProjectState: (state, payload) => {
    state.projects = state.projects.map((pr) => {
      if (pr.id === payload.project) pr.active = payload.active
      return pr
    })
  },
  setFilters(state, filters) {
    state.filters = { ...state.filters, ...filters }
  },
  resetFilters(state) {
    state.filters = getDefaultState().filters
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
