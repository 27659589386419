import { API } from 'aws-amplify'
import Logger from '../../tools/Logger'
import { getBalance } from '@/graphql/queries'

const getDefaultState = () => {
  return {
    loading: false,
    balances: [],
    usdtBalance: '0.00',
    projectsBalances: [],
    balanceByDate: [],
  }
}
const state = getDefaultState()

const getters = {
  balanceLoading: (state) => state.loading,
  isLoading: (state) => state.loading,
  balances: (state) => state.balances,
  usdtBalance: (state) => state.usdtBalance,
  projectsBalances: (state) => state.projectsBalances,
  getBalanceByDate: (state) => state.balanceByDate,
}
const actions = {
  async fetchBalance({ commit }, { selectedValue, currency }) {
    commit('setLoading', true)
    try {
      const res = await API.graphql({
        query: getBalance,
        variables: {
          input: { project: selectedValue, isWallet: true },
        },
      })
      const sourceProject = res?.data?.getBalance?.balances.find((elem) => elem.currency === currency)
      return sourceProject?.available_balance || '0.00'
    } catch (error) {
      Logger.log('Error fetching balance:', error)
    } finally {
      commit('setLoading', false)
    }
  },
  async getBalance({ commit }, { project, isWallet }) {
    commit('setLoading', true)
    const res = await API.graphql({
      query: getBalance,
      variables: {
        input: { project, isWallet },
      },
    })
    Logger.log(res)
    commit('setLoading', false)
    commit('setBalance', res.data.getBalance)
  },
  async getBalanceBulk({ commit, rootGetters }) {
    const promises = []
    const func = async (project) => {
      const res = await API.graphql({
        query: getBalance,
        variables: {
          input: { project: project.id },
        },
      })
      return {
        id: project.id.slice(0, 4),
        full_id: project.id,
        balance: res.data.getBalance.balances,
        merch_name: project.merchant_name,
        project_name: project.name,
      }
    }
    for (const project of rootGetters['projects/getProjects']) {
      promises.push(func(project))
    }
    const res = await Promise.all(promises)
    commit('setProjectBalances', res)
  },
  async balanceByDate({ commit, dispatch }, { project, date }) {
    commit('setLoading', true)
    const res = await API.graphql({
      query: getBalance,
      variables: {
        input: {
          project,
          date,
        },
      },
    })
    if (!res.data.getBalance.success) {
      dispatch('alert/setAlertData', { success: false, message: res.data.getBalance.message }, { root: true })
      commit('setLoading', false)
      return
    }
    commit('setBalanceByDate', res.data.getBalance.balanceByDate)
    commit('setLoading', false)
  },
  adjustProjectBalance({ commit }, payload) {
    commit('adjustProjectBalance', payload)
  },
  clearBalanceByDate({ commit }) {
    commit('clearBalanceByDate', [])
  },
}
const mutations = {
  setLoading: (state, payload) => (state.loading = payload),
  setBalance: (state, payload) => ((state.balances = payload.balances), (state.usdtBalance = payload.usdtBalance)),
  setProjectBalances: (state, payload) => (state.projectsBalances = payload),
  adjustProjectBalance: (state, payload) => {
    state.projectsBalances = state.projectsBalances.map((pr) => {
      if (pr.full_id === payload.id) {
        pr.balance.forEach((b) => {
          if (b.currency === payload.currency) {
            b.available_balance = payload.new_balance
          }
        })
      }
      return pr
    })
  },
  setBalanceByDate: (state, payload) => {
    state.balanceByDate = payload
  },
  clearBalanceByDate: (state, payload) => {
    state.balanceByDate = payload
  },
  clearState: (state) => {
    Object.assign(state, getDefaultState())
  },
}
export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
